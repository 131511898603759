import React, { useState, useEffect, useContext } from 'react'
import { Card, Checkbox, Tabs } from 'antd'
import CheckboxGroup from '../../../../components/CheckboxGroup'
import { addCategory, useComponentSelectionError } from '../tab-component-utils'
import { TranslationsContext } from '../../../../store/translations'

const { TabPane } = Tabs

const MATERIALS = 'materials'
const DECO_GLASS  = 'decorativeGlass'
const SCENIC_WINDOW_TYPES = 'scenicWindowTypes'
const PANEL_ORIENTATION = 'panelOrientation'
const SIDE_WALL_PANELS = 'sideWallPanels'

const Walls = ({ initRestrictions = {}, setRestrictions, disableSaving, product, managingGlobal, saveDisabled, title }) => {
  const [materialsRestrictions, setMaterialsRestrictions] = useState(initRestrictions.materials || {})
  const [decoGlassRestrictions, setDecoGlassRestrictions] = useState(initRestrictions.decorativeGlass || {})
  const [scenicWindowTypeRestrictions, setScenicWindowTypeRestrictions] = useState(initRestrictions.scenicWindowTypes || [])
  const [panelOrientationRestrictions, setPanelOrientationRestrictions] = useState(initRestrictions.panelOrientation)
  const [sideWallPanelRestrictions, setSideWallPanelRestrictions] = useState(initRestrictions.sideWallPanels)

  const { handleError } = useComponentSelectionError(disableSaving)
  const [errorMessage, setErrorMessage] = useState('')

  const wallsData = product.componentsData.walls

  const { getText } = useContext(TranslationsContext)

  const { wallEditor } = product

  const showSidePanelOption = product.id !== 'home' && wallsData.sideWallPanels?.length > 0

  const showPanelOrientationOption = wallEditor?.panelOrientationSelector

  const showPanelingTab = showSidePanelOption || showPanelOrientationOption

  useEffect(() => {
    setRestrictions({
      materials: materialsRestrictions,
      decorativeGlass: decoGlassRestrictions,
      scenicWindowTypes: scenicWindowTypeRestrictions,
      panelOrientation: panelOrientationRestrictions,
      sideWallPanels: sideWallPanelRestrictions
    })
  }, [materialsRestrictions, decoGlassRestrictions, panelOrientationRestrictions, scenicWindowTypeRestrictions, sideWallPanelRestrictions])

  const setError = message => {
    handleError("walls", message)
    setErrorMessage(message)
  }

  const handleWallsChange = (checkedValues, name) => {
    const restrictions = { ...materialsRestrictions }

    if (!restrictions[name]) {
      restrictions[name] = {}
    }

    restrictions[name].finishes = checkedValues
    setMaterialsRestrictions(restrictions)
  }

  const handleWallsCheckAllChange = (name, checked) => {
    const restrictions = { ...materialsRestrictions }
    
    if (!restrictions[name]) {
      restrictions[name] = {}
    }

    restrictions[name].disabled = checked
    
    setMaterialsRestrictions(restrictions)

    const allSelected = Object.values(restrictions).filter(v => v.disabled).length ===
      product.componentsData.walls.materials.length

    if (allSelected) {
      setError("You need to select at least one available material for the walls.")
    } else {
      setError('')
    }
  }

  const handlePanelOrientationChange = (checkedValues) => {
    setPanelOrientationRestrictions(checkedValues)
  }

  const handleSideWallPanelsChange = (checkedValues) => {
    setSideWallPanelRestrictions(checkedValues)
  }

  const handleDecoGlassChange = (checkedValues) => {
    setDecoGlassRestrictions(prev => ({
      ...prev,
      components: checkedValues
    }))
  }

  const handleScenicWindowTypesChange = (checkedValues) => {
    setScenicWindowTypeRestrictions(checkedValues)
  }

  const handleDecoGlassCheckAllChange = (name, checked) => {
    setDecoGlassRestrictions(prev => ({
      ...prev,
      disabled: checked
    }))
  }
  const handleScenicWallCheckAllChange = (name, checked) => {
    const { scenicWindowTypes = [] } = product.componentsData.walls;

    let updatedScenicWindow = [];
    if (checked) {
      updatedScenicWindow = scenicWindowTypes.map((item) => item.id);
    }
    setScenicWindowTypeRestrictions(updatedScenicWindow);
  };


  const { scenicWindowTypes = [] } = product.componentsData.walls

  return (
    <Card className="Walls component-card">
      <h2 className="component-card__title">{title}</h2>
      <Tabs className="component-card__main-tab" animated={false}>
        <TabPane disabled={saveDisabled} className="tab-pane" tab="Materials" key="1">
          {/* <p className="suggestion-text">Select material</p> */}
          {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
          <Tabs className="component-card__secondary-tab" animated={false}>
            {product.componentsData.walls.materials
              .filter(material => material.finishes.length > 0)
              .map(material => {
              const materialRestricted = materialsRestrictions[material.id] === 'all'
              let className = 'tab-pane-text'

              if (materialRestricted) {
                className += ' tab-pane-text-off'
              }
              return (
                <TabPane
                  className="tab-pane"
                  tab={<span className={className}>{getText(material.label)}</span>}
                  key={material.id}>
                  <CheckboxGroup
                    key={material.id}
                    title={material.label}
                    name={material.id}
                    handleChange={handleWallsChange}
                    defaultValue={materialsRestrictions[material.id]}
                    handleCheckAllChange={handleWallsCheckAllChange}
                    items={addCategory(material.finishes)}
                    categorizeBy="category"
                    requireSelection
                    setError={handleError}
                    errorMsg="You have to select at least one finish for a material."
                    checkAll
                    subtitle={material.finishes && material.finishes.length > 0 ? "Material finish" : ''}
                    managingGlobal={managingGlobal}
                  />
                </TabPane>
              )
            })}
          </Tabs>
        </TabPane>
        {product.componentsData.walls.decorativeGlass
          .filter(x => managingGlobal || !x.disabled).length > 1 &&
          <TabPane disabled={saveDisabled} className="tab-pane" tab="Decorative glass combinations" key="2">
            <>
              {/* <h2>Decorative glass combinations</h2> */}
              <CheckboxGroup
                // title=""
                name="decorativeGlass"
                title="Decorative glass"
                handleChange={handleDecoGlassChange}
                handleCheckAllChange={handleDecoGlassCheckAllChange}
                defaultValue={decoGlassRestrictions?.components}
                items={product.componentsData.walls.decorativeGlass.filter(x => {
                  if (x.id === 'DECO0') return false
                  return true
                })}
                requireSelection
                setError={handleError}
                errorMsg="You have to select at least one decorative glass combination."
                checkAll
                subtitle={"Combinations"}
                managingGlobal={managingGlobal}
              />
            </>
          </TabPane>
        }
        {scenicWindowTypes.length > 0 && 
        <TabPane disabled={saveDisabled} className="tab-pane" tab="Scenic window types" key="3">
          <CheckboxGroup
            name="scenicWindowTypes"
            title="Scenic Window Types"
            mainTitle
            handleChange={handleScenicWindowTypesChange}
            defaultValue={scenicWindowTypeRestrictions}
            handleCheckAllChange={handleScenicWallCheckAllChange}
            items={scenicWindowTypes}
            setError={handleError}
            checkAll
            requireSelection
            managingGlobal={managingGlobal}
          ></CheckboxGroup>
        </TabPane>}
        { showPanelingTab && 
          <TabPane tab="Paneling" key="paneling">
            {
              showSidePanelOption &&
              <CheckboxGroup
                name="sideWallPanels"
                title="Side wall paneling"
                disableSort={true}
                defaultValue={sideWallPanelRestrictions}
                items={wallsData.sideWallPanels}
                requireSelection
                setError={handleError}
                handleChange={handleSideWallPanelsChange}
                managingGlobal={managingGlobal}
              />
            }
            { showPanelOrientationOption &&
              <CheckboxGroup
                name="panelOrientation"
                title="Panel orientation"
                items={product.componentsData.walls.panelOrientation}
                handleChange={handlePanelOrientationChange}
                defaultValue={panelOrientationRestrictions}
                setError={handleError}
                managingGlobal={managingGlobal}
              />
            }
          </TabPane>
        }
      </Tabs>
    </Card>
  )
}

export default Walls