import React from 'react'
import { Breadcrumb, message, Radio, Spin, Checkbox, Modal } from 'antd'
import './ManagePredesignsPage.scss'
import { Link } from 'react-router-dom'
import { useState, useEffect, useContext, useMemo } from 'react'
import { FrontlinesContext } from '../../store/frontlines'
import { TranslationsContext } from '../../store/translations'
import { APIContext } from '../../store/api'
import AsyncButton from '../../components/AsyncButton'
import { createErrorMessage } from '../../utils/error-utils'
import { notify as notifySaveSuccess } from '../../components/SaveNotification'
import { useFrontline } from '../../store/frontlines/useFrontline'

function ManagePredesignsPage(props) {
  const { match, history } = props

  const { 
    loadFrontline,
    globalFrontline,
    saveAvailablePredesigns,
    releaseProduct
  } = useContext(FrontlinesContext)

  const api = useContext(APIContext)

  const { getText } = useContext(TranslationsContext)

  const { productId, releaseId, country } = match.params

  const productName = getText(`product-${productId}`)

  const frontline = useFrontline()

  const managingGlobal = useMemo(() => {
    if (!frontline) return false
    return frontline.hiddenId.toLowerCase() === 'global'
  }, [frontline])

  const [ loading, setLoading ] = useState(true)

  const [ predesignGroups, setPredesignGroups ] = useState()
  const [ selectedPredesignGroup, setSelectedPredesignGroup ] = useState()
  const [ selectedPredesigns, setSelectedPredesigns ] = useState()

  const themes = useMemo(() => {
   if (!predesignGroups) return []

    return predesignGroups
      .find(x => x.id === selectedPredesignGroup)
      .availableDesigns
      .reduce((prev, curr) => {
        const existing = prev.find(x => x.id === curr.theme)

        if (existing) {
          existing.designs.push(curr)
        } else {
          prev.push({
            id: curr.theme,
            designs: [curr]
          })
        }

        return prev
      }, [])

  }, [predesignGroups, selectedPredesignGroup])

  useEffect(() => {
    const apiToUse = managingGlobal ? 'globalData' : 'global'
    const promises = []
    
    promises.push(loadFrontline({ country }))
    promises.push(api.get(`/package/${apiToUse}/${productId}?all_designs=true&no_release_fallback=true&releaseId=${releaseId}`))

    setLoading(true)
    Promise.all(promises).then(([frontline, productData]) => {
      const { designs } = productData

      if (!designs || designs.length === 0) {
       Modal.info({
             title: `Predesigns`,
             content: `Not available for '${productId}' Product`,
             okText: 'Ok',
             onOk() {
              const path = history.location.pathname;
              return history.push(path.substring(0, path.indexOf('product')))
            },
           })
        return
      }

      const selectablePredesignGroups = designs.reduce((prev, curr) => {
        const { 
          id,
          name,
          theme,
          selectablePredesignSet
        } = curr
 
        // Set already created?
        const existing = prev.find(x => x.id === selectablePredesignSet)

        if (existing) {
          // Set exists, just add the design
          existing.availableDesigns.push({ id, name, theme })
        } else {
          // No set yet. Create it and initialize with the design
          prev.push({
            id: selectablePredesignSet,
            name: selectablePredesignSet && selectablePredesignSet.split('-')[1],
            availableDesigns: [{ id, name, theme }]
          })
        }

        return prev
      }, [])

      const { predesigns = {} } = frontline

      let group, availablePredesigns

      if (predesigns[productId] && predesigns[productId][releaseId]) {
        group = predesigns[productId][releaseId].group
        availablePredesigns = predesigns[productId][releaseId].availablePredesigns
      }

      if (group && selectablePredesignGroups.find(x => x.id === group)) {
        setSelectedPredesignGroup(group)
      } else {
        setSelectedPredesignGroup(selectablePredesignGroups[0].id)
      }

      if (availablePredesigns) {
        setSelectedPredesigns(availablePredesigns)
      } else {
        setSelectedPredesigns(selectablePredesignGroups[0].availableDesigns.map(x => x.id))
      }

      setPredesignGroups(selectablePredesignGroups)

      setLoading(false)
    }, (err) => {
      console.error(err)
      history.push(`manage`)
    })

  }, [ country, releaseId, productId ])

  if (loading || !frontline) return <div className="page spinner-container"><Spin className="page__loading-spinner" size="large" /></div>

  function handleCheckbox(designId, checked) {
    if (checked) {
      setSelectedPredesigns([...selectedPredesigns, designId])
    } else {
      setSelectedPredesigns(prev => {
        return prev.filter(x => x !== designId)
      })
    }
  }

  function handleRadioButton(e) {
    setSelectedPredesignGroup(e.target.value)
  }

  async function handleSave() {    
    const failedFrontlines = []
    try {
      if (managingGlobal) {
        const result = await saveAvailablePredesigns(globalFrontline, { predesigns: selectedPredesigns, productId, releaseId })
        if (result.failedFrontlines.length > 0) {
          failedFrontlines.push(...result.failedFrontlines)
        }
      } else {
        await saveAvailablePredesigns(frontline, { group: selectedPredesignGroup, predesigns: selectedPredesigns, productId, releaseId })
      }

      const fl = managingGlobal ? globalFrontline : frontline

      notifySaveSuccess({
        productId,
        frontline: fl,
        productName,
        releaseId,
        releaseProduct,
        failedFrontlines,
        releaseAllowed: globalFrontline.allowRelease,
      })
    } catch (err) {
      const errorMessage = createErrorMessage(err, {
        attemptedOperation: 'Saving the predesign data',
        attemptedItem: 'frontline'
      })

      message.error(errorMessage, 5)
    }
  }
console.log(predesignGroups)
  return (
  
    <div className="ManagePredesignsPage page">
       <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}`}>{frontline.country.name}</Link>
        </Breadcrumb.Item>
        {
          !managingGlobal &&
          <Breadcrumb.Item>
          <Link to={`/frontline/${frontline.hiddenId}/products`}>Products</Link>
        </Breadcrumb.Item>
        }
        <Breadcrumb.Item>
          <Link to={`manage`}>{productName} ({releaseId})</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Predesigns
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="page__title">Predesigns for {productName} ({releaseId})</h1>
      {
        // NOTE: Only allow wide cars as of 2021/01/07. Some deep predesigns are invalid because of invalid back wall panelization.
        false && !managingGlobal && predesignGroups && predesignGroups.length > 1 && 
      <div className="block">
        <h2 className="block__title">
          Predesign sets
        </h2>
        <Radio.Group 
          className="design-set-radio-button-group"
          name="designsets"
          onChange={handleRadioButton}
          defaultValue={selectedPredesignGroup}>
          {
            predesignGroups.map(x => {

              return (
                <Radio 
                  className="design-set-radio-button"
                  key={x.id} 
                  value={x.id}>{x.name}</Radio>
              )
            })
          }
        </Radio.Group>
        </div>
      }
      <div className="block">
          <h2 className="block__title">Predesigns</h2>
          <div className="themes-container">
            {
              themes && themes
              .map(theme => {
                return (
                  <div key={theme.id} className="theme-block">
                    <h3>{getText(`theme-${theme.id}`)}</h3>
                    <div className="predesigns-container">
                    {
                      theme.designs.map(design => {
                        const checked = selectedPredesigns && selectedPredesigns.includes(design.id)
                        return (
                          <Checkbox
                            className="predesign-checkbox"
                            value={design.id}
                            key={design.id}
                            checked={checked}
                            onChange={() => handleCheckbox(design.id, !checked)}
                          >{getText(design.name)}</Checkbox>
                        )
                      })
                    }
                    </div>
                  </div>
                )
              })
            }
          </div>
      </div>
      <div className="block buttons-container">
            {/* <Button>Cancel</Button> */}
            <AsyncButton 
              onConfirm={handleSave}
              type="primary">
                Save
              </AsyncButton>
          </div>
    </div>
  )
}

export default ManagePredesignsPage